/** COPY and PASTE from ec-mobile. Naming it nextTick for familiarity.
 * 可以理解为异步执行
 * requestIdleCallback 是浏览器空闲时会自动执行内部函数
 * requestAnimationFrame 是浏览器必须执行的
 * 关于 requestIdleCallback 和  requestAnimationFrame 可以参考 https://www.cnblogs.com/cangqinglang/p/13877078.html
 */
export const nextTick = (callback: any, delay: number = 17) => {
  if (typeof window == 'undefined') return null;

  var _window = window as any;
  if (typeof _window?.requestIdleCallback === 'function') {
    _window?.requestIdleCallback(callback, { timeout: delay });
  } else if (typeof requestAnimationFrame === 'function') {
    _window?.requestAnimationFrame(callback);
  } else if (typeof Promise === 'function') {
    Promise.resolve().then(callback);
  } else setTimeout(callback, delay);
};
