import Tracker from '@/utils/track';
import { ADsTrackingDataType, ClickTargetType, ClickType, NewTracking } from '@/constants/track';
import { formatCtx, formatParam } from './useImpressionT2';
import { trackAdCreative } from '@/services/ads';
import { setClientNavigationPDPAdsAttribution } from '@/utils/common/ads-tracking';

const formatBanner = param => {
  if (!param) return null;
  let { banner_id, banner_key, url, banner_pos, banner_type, target_nm, ...rest } = param || {};
  return {
    ...rest,
    target_nm: (banner_id || banner_key || url || target_nm) ?? null,
    target_pos: banner_pos ?? null,
    target_type: banner_type ?? null,
    click_type: ClickType.view,
    url: url ?? null
  };
};

export const formatButton = param => {
  if (!param) return null;
  return {
    target_nm: param?.target_nm ?? null,
    target_pos: param?.target_pos ?? null,
    target_type: ClickTargetType.normalBtn,
    click_type: param.click_type || ClickType.normal,
    click_result: param?.click_result ?? null
  };
};

const formatEllipse = param => {
  if (!param) return null;
  return {
    target_nm: param?.ellipse_label ?? null,
    target_pos: param?.ellipse_pos ?? null,
    target_type: param?.ellipse_type ?? null,
    click_type: ClickType.view
  };
};

const commonFormat = param => {
  if (!param) return null;
  return {
    target_nm: param?.target_nm ?? null,
    target_pos: param?.target_pos ?? null,
    target_type: param?.target_type ?? null,
    click_type: param?.click_type ?? ClickType.view,
    is_mkpl: param?.is_mkpl ?? null,
    url: param?.url ?? null
  };
};

const formatSave = param => {
  if (!param) return null;
  return {
    target_nm: param?.target_nm ?? null,
    target_pos: param?.target_pos ?? null,
    target_type: ClickTargetType.product,
    click_type: ClickType.save,
    is_mkpl: param?.is_mkpl ?? null
  };
};

const formatUnSave = param => {
  if (!param) return null;
  return {
    target_nm: param?.target_nm ?? null,
    target_pos: param?.target_pos ?? null,
    target_type: ClickTargetType.product,
    click_type: ClickType.unSave,
    is_mkpl: param?.is_mkpl ?? null
  };
};

const formatSortFilter = param => {
  if (!param) return null;
  const { target_nm, target_pos, target_type, click_type, ...rest } = param || {};
  return {
    target_nm: target_nm ?? null,
    target_pos: target_pos ?? null,
    target_type: target_type ?? null,
    click_type: click_type || ClickType.normal,
    ...rest
  };
};

const formatFilterBtn = param => {
  if (!param) return null;
  return {
    target_nm: param?.button_nm ?? null,
    target_pos: param?.button_pos ?? null,
    target_type: ClickTargetType.filterBtn,
    click_type: ClickType.view
  };
};

const formatCommon = param => {
  if (!param) return null;
  return {
    ...param,
    target_nm: param?.target_nm ?? null,
    target_pos: param?.target_pos ?? null,
    target_type: param?.target_type ?? null,
    click_type: ClickType.view
  };
};

export const formatCustomize = param => {
  if (!param) return null;
  const { target_nm, target_pos, target_type, click_type, ...rest } = param || {};
  return {
    target_nm: target_nm ?? null,
    target_pos: target_pos ?? null,
    target_type: target_type ?? null,
    click_type: click_type || ClickType.view,
    ...rest
  };
};

const formatShare = param => {
  if (!param) return null;
  return {
    target_nm: param?.target_nm ?? null,
    target_pos: param?.target_pos ?? null,
    target_type: param?.target_type ?? null,
    click_type: ClickType.share
  };
};

const formatPopup = param => {
  if (!param) return null;
  return {
    action: param?.action ?? null,
    name: param?.name ?? null,
    id: param?.id ?? null,
    content_type: param?.content_type ?? null,
    url: param?.url ?? null,
    other_parameter: param?.other_parameter ?? null,
    target_url: param?.target_url ?? null,
    target_nm: param?.target_nm ?? null
  };
};

export default function useClickActionT2(
  trackDataInDialog?: Record<string, any>,
  adsTrackingData?: ADsTrackingDataType
) {
  const sendTracker = (eventType: string, data: any) => {
    if (trackDataInDialog) {
      new Tracker().addEventInDialog(eventType, data, trackDataInDialog).send();
    } else {
      new Tracker().addEvent(eventType, data).send();
    }
  };
  const bannerClick = param => {
    const params = formatParam(param);
    params.co = formatBanner(params?.co);
    sendTracker(NewTracking.clickAction, params);
  };

  const buttonClick = param => {
    const params = formatParam(param);
    params.co = formatButton(params?.co);
    sendTracker(NewTracking.clickAction, params);
  };

  //click_type是view或者normal的标准：点击页面是否看到新的内容，已经出现的不算
  const buttonClickView = param => {
    const params = formatParam(param);
    params.co = formatButton({
      ...(param?.co || {}),
      click_type: ClickType.view
    });
    sendTracker(NewTracking.clickAction, params);
  };

  const ellipseClick = param => {
    const params = formatParam(param);
    params.co = formatEllipse(params?.co);
    sendTracker(NewTracking.clickAction, params);
  };

  const productClick = param => {
    const params = formatParam(param);
    params.co = commonFormat(params?.co);
    if (params?.ctx) {
      params.ctx = formatCtx(params?.ctx);
    }
    sendTracker(NewTracking.clickAction, params);
    try {
      if (params?.co?.target_type == 'product' && params?.co?.target_nm) {
        if (adsTrackingData?.trackClickUrl) {
          trackAdCreative(adsTrackingData.trackClickUrl, adsTrackingData, params);
          setClientNavigationPDPAdsAttribution(params.co.target_nm, adsTrackingData);
        }
      }
    } catch (e) {}
  };

  const saveClick = (param, targetCollected: boolean) => {
    const params = formatParam(param);
    params.co = targetCollected ? formatSave(params?.co) : formatUnSave(params?.co);
    if (params?.ctx) {
      params.ctx = formatCtx(params?.ctx);
    }
    sendTracker(NewTracking.clickAction, params);
  };

  const sectionClick = param => {
    const params = formatParam(param);
    params.co = commonFormat(params?.co);
    sendTracker(NewTracking.clickAction, params);
  };

  const sortFilterClick = param => {
    const params = formatParam(param);
    params.co = formatSortFilter(params?.co);
    sendTracker(NewTracking.clickAction, params);
  };

  const filterBtnClick = param => {
    const params = formatParam(param);
    params.co = formatFilterBtn(params?.co);
    sendTracker(NewTracking.clickAction, params);
  };

  const commonClick = param => {
    const params = formatParam(param);
    params.co = formatCommon(params?.co);
    sendTracker(NewTracking.clickAction, params);
  };

  const shareClick = param => {
    const params = formatParam(param);
    params.co = formatShare(params?.co);
    sendTracker(NewTracking.clickAction, params);
  };

  const popupClick = param => {
    const params = formatParam(param);
    params.co = formatPopup(param?.co);
    sendTracker(NewTracking.popup, params);
  };

  const customizeClick = param => {
    const params = formatParam(param);
    params.co = formatCustomize(param?.co);
    sendTracker(NewTracking.clickAction, params);
  };

  return {
    bannerClick,
    buttonClick,
    ellipseClick,
    productClick,
    saveClick,
    sectionClick,
    sortFilterClick,
    filterBtnClick,
    commonClick,
    shareClick,
    buttonClickView,
    popupClick,
    customizeClick // co 部分参数可自定义
  };
}
