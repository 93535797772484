import { ADsTrackingDataType } from '@/constants/track';
export const isBrowser = typeof window !== 'undefined' && typeof document !== 'undefined';

export const setClientNavigationPDPAdsAttribution = (productId: number, adsTrackingData: ADsTrackingDataType) => {
  try {
    if (!isBrowser || !productId) return;
    const _window = window as any;
    if (!_window.__adsAttributionHelper) _window.__adsAttributionHelper = {};
    _window.__adsAttributionHelper.lastSponsoredClick = {
      productId,
      adsTrackingData
    };
  } catch (e) {}
};

export const getClientNavigationPDPAdsAttribution = (productId: number): void | ADsTrackingDataType => {
  try {
    if (!isBrowser || !productId) return;
    const _window = window as any;
    if (
      _window?.__adsAttributionHelper?.lastSponsoredClick?.productId == productId &&
      _window?.__adsAttributionHelper?.lastSponsoredClick?.adsTrackingData
    ) {
      const value = _window?.__adsAttributionHelper?.lastSponsoredClick?.adsTrackingData;
      delete _window?.__adsAttributionHelper?.lastSponsoredClick;
      return value as ADsTrackingDataType;
    }
  } catch (e) {}
};
