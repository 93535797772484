import fetchForAds, { NewAxiosRequestConfig as adsConfig } from '@/utils/ajax';
import { ADsTrackingDataType } from '@/constants/track';
import { nextTick } from '@/utils/next-tick';

const DELAY_BATCH_FLUSH_IN_MS = 1000;
const CPU_IDDLE = 100;

let AdsTrackQueue = [];
let deboundHandler = null;

const cpuIddleIfSupportedOrImmediately = (callback, delay) => {
  if (typeof window == 'undefined') return null;

  var _window = window as any;

  if (typeof _window?.requestIdleCallback === 'function') {
    nextTick(callback, delay);
  } else {
    callback();
  }
};

const flushTrackDebounced = () => {
  deboundHandler = null;
  cpuIddleIfSupportedOrImmediately(() => {
    if (AdsTrackQueue.length) {
      const toFlush = AdsTrackQueue;
      if (toFlush?.length == 0) return null;

      AdsTrackQueue = [];
      toFlush.forEach(params => {
        try {
          let { url, adsTrackingData, analyticsUIContext, config } = params || {};
          let _url = url.replace('api.anycart.com', 'click.sayweee.com');

          if (!config) config = {};
          if (!config?.headers) config.headers = {};
          config.headers = Object.assign(config.headers, {
            'X-Customization-Id': 'weee',
            'X-App-Flavor': 'weee'
          });
          fetchForAds.get(_url, {}, config);
        } catch (e) {
          console.error('[ads]_failed-to_flushTrackDebounced params:', params);
        }
      });
    }
  }, CPU_IDDLE);
};

/**
 * trackAdCreative
 * @param url string
 * @param adsTrackingData ADsTrackingDataType
 * @param analyticsUIContext object (is usually the weee-analytics payload - may come in handy to enhance ads analytics capabilities. Not used YET)
 * @param config NewAxiosRequestConfig
 */
export const trackAdCreative = (
  url: string,
  adsTrackingData: ADsTrackingDataType,
  analyticsUIContext?: object,
  config?: adsConfig
): any => {
  try {
    if (deboundHandler) clearTimeout(deboundHandler);
    AdsTrackQueue.push({ url, adsTrackingData, analyticsUIContext, config });
    deboundHandler = setTimeout(flushTrackDebounced, DELAY_BATCH_FLUSH_IN_MS);
  } catch (e) {
    console.error('[ads]_failed-to_trackAdCreative url:', url);
  }
};
