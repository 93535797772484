import { ADsTrackingDataType, NewTracking } from '@/constants/track';
import { trackAdCreative } from '@/services/ads';
import Tracker from '@/utils/track';

export const formatParam = param => {
  return {
    mod_nm: param?.mod_nm ?? null,
    mod_pos: param?.mod_pos ?? null,
    sec_nm: param?.sec_nm ?? null,
    sec_pos: param?.sec_pos ?? null,
    co: param?.co,
    ctx: param?.ctx
  };
};
const formatBanner = param => {
  if (!param) return null;
  return {
    banner_id: param?.banner_id ? `${param?.banner_id}` : void 0,
    banner_key: param?.banner_key ?? void 0,
    banner_pos: param?.banner_pos ?? void 0,
    banner_type: param?.banner_type ?? void 0,
    url: param?.url ?? void 0,
    message_type: param?.message_type ?? void 0
  };
};
const formatEllipse = param => {
  if (!param) return null;
  return {
    ellipse_label: param?.ellipse_label ?? void 0,
    ellipse_pos: param?.ellipse_pos ?? void 0,
    ellipse_type: param?.ellipse_type ?? void 0,
    is_new: param?.is_new ?? void 0
  };
};
const formatFilterBtn = param => {
  if (!param) return null;
  return {
    button_nm: param?.button_nm ?? void 0,
    button_pos: param?.button_pos ?? void 0,
    is_selected: param?.is_selected ?? void 0,
    is_available: param?.is_available ?? void 0,
    is_soldout: param?.is_soldout ?? void 0
  };
};
export const formatCtx = param => {
  if (!param) return null;
  return {
    ...param,
    filter_sub_category: param?.filter_sub_category ?? void 0,
    catalogue_num: param?.catalogue_num ?? void 0,
    sort: param?.sort ?? void 0,
    filters: param?.filters ?? void 0,
    page_target: param?.page_target ?? void 0,
    page_tab: param?.page_tab ?? void 0,
    brand_name: param?.brand_name ?? void 0,
    global_vendor: param?.global_vendor ?? void 0,
    group_filter: param?.group_filter ?? void 0
  };
};
export const formatProductInfo = (data, isFromCart) => {
  if (!data) return null;
  // 没有展示在页面上的数据，不传，that's why we need those showXXX
  return {
    prod_pos: data?.index ?? void 0,
    prod_id: data?.id || data?.product_id,
    prod_name: (isFromCart ? data?.title : data?.name) ?? void 0,
    price: (data?.showSpecialPrice ? data?.special_price : data?.price) ?? void 0,
    base_price:
      data?.base_price && data?.base_price > (data?.showSpecialPrice ? data?.special_price : data?.price)
        ? data?.base_price
        : void 0,
    discount_percentage: data?.discount_percentage ?? void 0,
    sold_status: (isFromCart ? data?.reason_type : data?.sold_status) ?? void 0,
    last_week_sold_count_ui:
      data?.showWeekly && data?.last_week_sold_count_ui !== '0' ? data?.last_week_sold_count_ui || void 0 : void 0,
    is_pantry: data?.is_pantry ?? void 0,
    is_limit_product: data?.is_limit_product ?? void 0,
    is_sponsored: data?.is_sponsored ?? void 0,
    is_manual: data?.is_manual,
    is_mkpl: data?.is_mkpl ?? void 0,
    is_fbw: data?.biz_type === 'fbw' ? true : false,
    restock_tip: data?.restock_tip ?? void 0,
    policy_pop_config_key: data?.policy_pop_config_key ?? null,
    progress: data?.progress ?? void 0,
    qty_in_cart: data?.qty_in_cart ?? void 0,
    activity_tag_list: data?.showActivity && data?.activity_tag_list?.length > 0 ? data?.activity_tag_list : void 0,
    label_list:
      data?.showLabel && data?.label_list?.map(vv => vv?.label_name)?.slice(0, 1)?.length > 0
        ? data?.label_list?.map(vv => vv?.label_name)?.slice(0, 1)
        : void 0,
    recommendation_trace_id: data?.recommendation_trace_id ?? void 0,
    is_hotdish: data?.is_hotdish ?? void 0,
    tag_key: data?.tag_key,
    tag_name: data?.tag_name,
    volume_price_support: data?.volume_price_support ? true : false,
    biz_type: data?.biz_type || void 0,
    is_presale: data.is_presale,
    sale_event_id: data?.sale_event_id
  };
};

const formatItem = items => {
  if (!items?.length) return null;
  return items?.map(item => {
    return {
      product_id: item?.product_id ?? void 0,
      quantity: item?.quantity ?? void 0,
      source: item?.source ?? void 0,
      price: item?.price ?? void 0,
      base_price: item?.base_price ?? void 0,
      reason_type: item?.reason_type,
      status: item?.status,
      volume_price_support: item?.volume_price_support ? true : false,
      promotion_type: item.promotion_type ?? void 0,
      promotion_offer: item.promotion_offer ?? void 0
    };
  });
};

const formatItems = data => {
  let allProducts = [];
  let activityAll = [];
  let itemsAll = [];
  const activityInfo = data?.activity_info?.filter(vv => vv?.type !== 'trade_in') || [];
  for (let i = 0; i < activityInfo?.length; i++) {
    if (!!activityInfo[i]?.items?.length) {
      for (let m = 0; m < activityInfo[i]?.items?.length; m++) {
        let item = activityInfo[i]?.items[m];
        if (activityInfo[i]?.source === 'ec-promotion' && !!item) {
          item = {
            promotion_type: activityInfo[i]?.original_type,
            promotion_offer: activityInfo[i]?.is_offer,
            ...item
          };
        }
        activityAll = activityAll?.concat(item || []);
        if (!!activityInfo[i]?.items[m]?.activity_info?.length) {
          for (let n = 0; n < activityInfo[i]?.items[m]?.activity_info?.length; n++) {
            activityAll = activityAll?.concat(activityInfo[i]?.items[m]?.activity_info[n]?.items || []);
          }
        }
      }
    }
  }

  const itemsInfo = data?.items;
  for (let i = 0; i < itemsInfo?.length; i++) {
    itemsAll = itemsAll?.concat(itemsInfo[i] || []);
    if (!!itemsInfo[i]?.activity_info?.length) {
      for (let m = 0; m < itemsInfo[i]?.activity_info?.length; m++) {
        itemsAll = itemsAll?.concat(itemsInfo[i]?.activity_info[m]?.items || []);
      }
    }
  }
  allProducts = activityAll?.concat(itemsAll);
  return formatItem(allProducts);
};

export const formatCart = data => {
  if (!data) return null;
  // 没有展示在页面上的数据，不传，that's why we need those showXXX
  return {
    type: data?.type ?? void 0,
    delivery_pickup_date: data?.delivery_pickup_date ?? void 0,
    delivery_mode: data?.delivery_mode ?? void 0,
    deal_id: data?.deal_id ?? void 0,
    sub_total_price: data?.sub_total_price ?? void 0,
    total_price_with_activity: data?.total_price_with_activity ?? void 0,
    shipping_fee: data?.shipping_fee ?? void 0,
    shipping_desc:
      data?.total_price_with_activity < data?.shipping_free_fee && data?.shipping_desc ? data?.shipping_desc : void 0,
    quantity: data?.quantity ?? void 0,
    orignal_shipping_fee: data?.orignal_shipping_fee ?? void 0,
    vendor_id: data?.vendor_id ?? void 0,
    hotdish_wave: data?.hotdish_wave ?? void 0,
    items: formatItems(data) ?? void 0,
    trade_in_items: formatItem(data?.activity_info?.find(vv => vv?.type === 'trade_in')?.items) ?? void 0
  };
};

const formatPopup = param => {
  if (!param) return null;
  return {
    action: param?.action ?? void 0,
    name: param?.name ?? void 0,
    id: param?.id ?? void 0,
    content_type: param?.content_type ?? void 0,
    url: param?.url ?? void 0,
    other_parameter: param?.other_parameter ?? void 0,
    target_url: param?.target_url ?? void 0
  };
};

export default function useImpressionT2(
  trackDataInDialog?: Record<string, any>,
  adsTrackingData?: ADsTrackingDataType
) {
  const sendTracker = (eventType: string, data: any) => {
    if (trackDataInDialog) {
      new Tracker().addEventInDialog(eventType, data, trackDataInDialog).send();
    } else {
      new Tracker().addEventInQueue(eventType, data).send();
    }
  };
  const bannerImpression = param => {
    const params = formatParam(param);
    params.co = formatBanner(params?.co);

    if (params?.ctx) {
      params.ctx = formatCtx(params?.ctx);
    }
    sendTracker(NewTracking.bannerImp, params);
  };

  const ellipseImpression = param => {
    const params = formatParam(param);
    params.co = formatEllipse(params?.co);
    sendTracker(NewTracking.ellipseImp, params);
  };

  const filterBtnImpression = param => {
    const params = formatParam(param);
    params.co = formatFilterBtn(params?.co);
    sendTracker(NewTracking.filterImp, params);
  };

  const productImpression = (param, isFromCart = false) => {
    if (param) {
      const params = formatParam(param);
      params.co = formatProductInfo(params?.co, isFromCart);
      if (params?.ctx) {
        params.ctx = formatCtx(params?.ctx);
      }
      sendTracker(NewTracking.productImp, params);
      try {
        if (adsTrackingData?.trackViewUrl) {
          trackAdCreative(adsTrackingData.trackViewUrl, adsTrackingData, params);
        }
      } catch (e) {}
    }
  };

  const cartImpression = param => {
    const params = formatParam(param);
    params.co = formatCart(params?.co);
    sendTracker(NewTracking.cartImp, params);
  };

  const pageSectionImpression = param => {
    const params = formatParam(param);
    sendTracker(NewTracking.pageSectionImp, params);
  };

  const popupImpression = param => {
    const params = formatParam(param);
    params.co = formatPopup(params?.co);
    new Tracker().addEvent(NewTracking.popup, params).send();
  };

  const videoImpression = param => {
    const params = formatParam(param);
    params.co = formatBanner(params?.co);
    if (params?.ctx) {
      params.ctx = formatCtx(params?.ctx);
    }
    sendTracker(NewTracking.bannerImp, params);
  };

  return {
    bannerImpression,
    ellipseImpression,
    filterBtnImpression,
    productImpression,
    cartImpression,
    pageSectionImpression,
    popupImpression,
    videoImpression
  };
}
